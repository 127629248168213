<template>
  <v-form ref="form" v-model="valid" @submit="submit">
    <h1 class="custom-title-large"
      >Vamos iniciar nossa <span class="font-weight-black">parceria?</span></h1
    >
    <p class="custom-normal-text text-green">
      Antes de começar nossa conversa, precisamos saber seu nome, telefone e seu
      e-mail.
      <br />
      É importante que estes dados sejam válidos.
    </p>
    <p class="custom-normal-text text-green font-weight-bold">
      Não se preocupe, nada será cobrado neste momento ;)
    </p>
    <v-row no-gutters class="mt-2">
      <v-col cols="12">
        <v-text-field
          label="Nome Completo"
          v-model="form.name"
          :rules="rules.fullName"
          required
          validate-on-blur
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Telefone com DDD"
          :rules="rules.phone"
          v-mask="['(##) #####-####', '(##) ####-####']"
          v-model="form.phone"
          required
          validate-on-blur
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          ref="email"
          label="E-mail"
          v-model="form.email"
          :rules="rules.email"
          required
          validate-on-blur
          @focus="changedEmail()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="text-center">
      <v-col cols="12">
        <v-btn
          @click="submit()"
          :loading="loading"
          class="custom-btn-green rounded-0 btn-large"
        >
          próxima
        </v-btn>
      </v-col>
      <v-col cols="12" class="mt-5" v-show="emailAlreadyInUse">
        <span class="text-warn">
          Identificamos que você já está cadastrado em nosso sistema. <br />
          <router-link :to="'/login'" class="custom-text-link"
            >Clique aqui para acessar</router-link
          >
        </span>
      </v-col>
      <img
        v-if="conversion"
        :src="`https://smartbmc.com.br/i/smp/conv/lead/0.00/${id}`"
        width="1"
        height="1"
        style="display: none"
      />
    </v-row>
  </v-form>
</template>

<script>
import { fullName } from '@src/services/rules'
import { mapGetters } from 'vuex'
import SalesPartner from '@src/services/sales-partner'
import SalesCoupon from '@src/services/sales-coupon'

import { authComputed, authMethods } from '@state/helpers'

export default {
  data() {
    return {
      step: 1,
      valid: false,
      conversion: false,
      emailAlreadyInUse: false,
      form: {
        name: '',
        email: '',
        phone: '',
      },
      id: null,
      loading: false,
      rules: {
        fullName,
        email: [
          (v) => !this.emailAlreadyInUse || 'Esse email já está cadastrado',
          (v) =>
            /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'Email precisa ser válido.',
          (v) => !!v || 'Por favor preencha o email.',
        ],
        phone: [
          (v) =>
            v.length > 9 || 'O número de telefone precisa ter mais 10 digitos.',
          (v) => !!v || 'Por favor preencha o telefone.',
        ],
      },
      formUser: {},
    }
  },
  computed: {
    ...authComputed,
    currentPartnership() {
      if (!!this.$route.query.id_parceiro) {
        let idParceiro = this.$route.query.id_parceiro
          ? this.$route.query.id_parceiro.match(/\d/g).join('')
          : null
        SalesPartner.saveId(idParceiro)
      }
      return SalesPartner.getId()
    },
    currentCoupon() {
      if (!!this.$route.query.cupom) {
        SalesCoupon.saveId(this.$route.query.cupom)
      }
      return SalesCoupon.getId()
    },
  },
  methods: {
    ...authMethods,
    async changedEmail() {
      this.$store.commit('formDataUser/setEmailAvailable')
    },
    async submit() {
      this.emailAlreadyInUse = false
      if (!this.$refs.form.validate()) return
      this.loading = true
      try {
        let formUser = await this.createUser(this.getDataToSend())
        this.googleConversion()
        this.id = formUser.id
        this.$router.push({ name: 'quiz' })
      } catch (error) {
        this.loading = false
        if (!!error.errors.user) {
          this.emailAlreadyInUse = true
        }
        this.$refs.form.validate()
      }
    },
    getDataToSend() {
      let data = {
        email: this.form.email.toLowerCase().trim(),
        name: this.form.name,
        partnershipId: this.currentPartnership,
        coupon: this.currentCoupon,
        phone: this.$options.filters.unmaskText(this.form.phone),
      }
      return data
    },
    googleConversion() {
      this.$GTAG('event', 'conversion', {
        send_to: 'AW-1006960147/u-DqCKmLj5QBEJP8k-AD',
      })
      this.$GTAG('event', 'conversion', {
        sendTo: 'AW-1006960147',
      })
      this.$ga.event({
        eventCategory: 'new lead',
        eventAction: 'new lead',
        eventLabel: 'new lead',
        eventValue: 0,
      })
      this.conversion = true
    },
  },
}
</script>

<style scoped lang="scss">
@import '@design';
</style>

import Cookies from 'js-cookie'
import store from '@state/store'

const cookieName = 'coupon'
const conf = { domain: process.env.VUE_APP_COOKIE_DOMAIN }

export default {
  saveId(code) {
    Cookies.set(cookieName, code, conf)
  },
  getId() {
    return (
      Cookies.get(cookieName) ||
      (!!store.state.currentUser ? store.state.currentUser.idParceiro : null)
    )
  },
}
